import { dist } from "./dist";
import * as am5 from "@amcharts/amcharts5";
//require('dotenv').config();

export const helpers = {
  initSession: (userid, supplierSelect, navBtns, pgstr) => {
    return new Promise((resolve, reject) => {
      window.pgstr = pgstr;
      // helpers.createCookie("userID", pgstr.userid); //commented untill find a reason to use
      //helpers.buildSelectOptions(supplierSelect,'supplierSelect');
      for (let i = 0; i < navBtns.length; i++) {
        let key = navBtns[i].key;
        let value = navBtns[i].value;
        helpers.id(navBtns[i].id).innerHTML = navBtns[i].text;
        helpers.eListen(
          navBtns[i].id,
          () => {
            let obj = {};
            obj[key] = value;
            helpers.prep(obj);
          },
          "click"
        );
      }
      helpers.eListen(
        "supplierSelect",
        () => {
          helpers.prep({ supplier: helpers.getSelectedValue("supplierSelect") });
        },
        "change"
      );
      helpers.prep({});
      dist.admin_notifications({});
      resolve();
    });
  },
  initPageInstance: async (pageInstance, data) => {
    await pageInstance.initPage(data || null);
  },
  prep: async (recipe) => {
    if (Object.keys(recipe).length == 0) {
      recipe = pgstr;
    }
    if (recipe.hasOwnProperty("top_nav")) {
      //top nav click -> {top_nav : 'a_pnp'}
      pgstr.top_nav = recipe.top_nav;
      recipe.side_nav = pgstr.side_nav;
    }
    if (recipe.hasOwnProperty("side_nav") && recipe.side_nav != "login") {
      //side nav click -> {side_nav: 'rep_sales'}
      pgstr.side_nav = recipe.side_nav;
      recipe.top_nav = pgstr.top_nav;
      helpers.setActive("#side_nav", recipe.side_nav);
      helpers.setActive("#top_nav", recipe.top_nav);
    }
    if (recipe.hasOwnProperty("supplier")) {
      recipe.side_nav = pgstr.side_nav;
      recipe.top_nav = pgstr.top_nav;
      pgstr.supplier = recipe.supplier;
    }
    //{top_nav: 'DATA', side_nav: 'rep_sales'}
    console.log({ recipe });
    if (dist.hasOwnProperty(recipe.side_nav) && recipe.top_nav != "data"&& recipe.top_nav != "summary") {
      const html = await helpers.fetchHTML(recipe.side_nav);
      html && (helpers.id("content").innerHTML = html);
      dist[recipe.side_nav](recipe); //, hdrs={}, vars={}
    } else if (recipe.top_nav == "data") {
      const html = await helpers.fetchHTML(`admin_${recipe.side_nav}`);
      html && (helpers.id("content").innerHTML = html);
      dist[`admin_${recipe.side_nav}`](recipe); //, hdrs={}, vars={}
    }
    else if (recipe.top_nav == "summary") {
      const html = await helpers.fetchHTML(`summary_${recipe.side_nav}`);
      html && (helpers.id("content").innerHTML = html);
      dist[`summary_${recipe.side_nav}`](recipe); //, hdrs={}, vars={}
    }
  },
  fetchHTML: async (htmlFile) => {
    return new Promise((resolve, reject) => {
      // const html = `${prefix}${side_navName}.html`;
      const html = `../${htmlFile}.html`;
      fetch(html).then((response) => {
        if (response.status === 404) {
          reject(`404 - ${html} not found`);
        } else {
          window.scrollTo(0, 0);
          const side_nav = {};
          side_nav.ID = 1;
          side_nav.side_nav = htmlFile;
          resolve(response.text());
        }
      });
    });
  },

  eListen: (elID, callback, eventType) => {
    const oldNode = typeof elID === "string" ? helpers.id(elID) : elID;
    const newNode = oldNode.cloneNode(true);
    if (Array.isArray(callback) && Array.isArray(eventType)) {
      replace = true;
      for (let i = 0; i < callback.length; i++) {
        const singleCallback = callback[i];
        const singleEvent = eventType[i];
        newNode.addEventListener(singleEvent, () => {
          singleCallback();
        });
      }
    } else {
      const evnt = eventType ? eventType : "change";
      newNode.addEventListener(evnt, () => {
        callback();
      });
    }
    oldNode.parentNode.replaceChild(newNode, oldNode);
    // console.log('END ELISTEN',elID,callback);
  },
  id: (string) => {
    return document.getElementById(string);
  },
  divAtt: (element, att, value) => {
    const div = document.createElement(element);
    if (att) {
      const attribute = document.createAttribute(att);
      attribute.value = value || "";
      div.setAttributeNode(attribute);
    }
    return div;
  },
  addAtt: (element, att, value) => {
    // if (att) {
    //   const attribute = document.createAttribute(att);
    //   attribute.value = value || "";
    //   element.setAttributeNode(attribute);
    // }
    if (att) {
      if (att === "class") {
        element.classList.add(...value.split(" "));
      } else {
        const attribute = document.createAttribute(att);
        attribute.value = value || "";
        element.setAttributeNode(attribute);
      }
    }
  },
  buildSelectOptions: (optionsArray, selectElementId) => {
    var selectElement = helpers.id(selectElementId);
    selectElement.innerHTML = "";
    for (var i = 0; i < optionsArray.length; i++) {
      var option = document.createElement("option");
      option.value = optionsArray[i].value;
      option.text = optionsArray[i].text;
      selectElement.appendChild(option);
      if (optionsArray[i].value == pgstr.top_nav) {
        option.setAttribute("selected", "selected");
      }
    }
  },
  addSelectOption: (value, key, selectId) => {
    var selectElement = helpers.id(selectId);
    var option = document.createElement("option");
    option.value = key;
    option.text = value;
    selectElement.appendChild(option);
  },
  getSelectedValue: (selectElementId) => {
    var selectElement = document.getElementById(selectElementId);
    var selectedOption = selectElement.options[selectElement.selectedIndex];
    return selectedOption.value;
  },
  getSelectedText: (selectElementId) => {
    var selectElement = helpers.id(selectElementId);
    var selectedOption = selectElement.options[selectElement.selectedIndex];
    return selectedOption.textContent;
  },
  createCookie: (cKey, cValue) => {
    return new Promise((resolve, reject) => {
      document.cookie = `${cKey}=${cValue};secure,`;
      resolve(); // Resolve the promise when the cookie is set
    });
  },
  getCookie: (cKey) => {
    let name = cKey + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let cookie of ca) {
      let c = cookie;
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return 0;
  },

  buildTableData: async (thead_id, tbody_id, theadData, tbodyData) => {
    const thead = helpers.id(thead_id);
    thead.innerHTML = "";
    for (let row of theadData) {
      const tr = helpers.divAtt("tr", "", "");
      for (let col of row) {
        let th = helpers.divAtt("th", "", "");
        if (Array.isArray(col)) {
          if (Array.isArray(col[1])) {
            for (let i = 0; i < col[1].length; i++) {
              let attr = col[1][i];
              helpers.addAtt(th, attr.key, attr.value);
              th.innerHTML = col[0];
            }
          } else {
            let attr = col[1];
            helpers.addAtt(th, attr.key, attr.value);
            th.innerHTML = col[0];
          }
        } else {
          //th = helpers.divAtt("th", "", "");
          th.innerHTML = col;
        }
        tr.appendChild(th);
      }
      thead.appendChild(tr);
    }

    //build body
    const tbody = helpers.id(tbody_id);
    tbody.innerHTML = "";
    for (let row of tbodyData) {
      const tr = helpers.divAtt("tr", "", "");
      for (let col of row) {
        let td = helpers.divAtt("td", "", "");
        if (Array.isArray(col)) {
          if (Array.isArray(col[1])) {
            for (let i = 0; i < col[1].length; i++) {
              let attr = col[1][i];
              helpers.addAtt(td, attr.key, attr.value);
              td.innerHTML = col[0];
            }
          } else {
            let attr = col[1];
            helpers.addAtt(td, attr.key, attr.value);
            td.innerHTML = col[0];
          }
        } else {
          // td = helpers.divAtt("td", "", "");
          td.innerHTML = col;
        }
        tr.appendChild(td);
      }
      tbody.appendChild(tr);
    }
  },

  toggleLoader: (val) => {
    val ? helpers.id("loader").classList.remove("hide") : helpers.id("loader").classList.add("hide");
  },
  infoBox: (msg) => {
    helpers.id("infoBoxMessage").innerHTML = msg;
    helpers.id("infoBoxContainer").classList.remove("hide");
  },
  successBox: (msg) => {
    var popup = document.getElementById("successPopup");
    helpers.id("successPopup-msg").innerHTML = msg;
    popup.style.display = "block"; // Show the popup

    setTimeout(function () {
      popup.style.display = "none"; // Hide the popup after 2 seconds
    }, 3500);
  },
  errorBox: (msg) => {
    var popup = document.getElementById("errorPopup");
    helpers.id("errorPopup-msg").innerHTML = msg;
    popup.style.display = "block"; // Show the popup

    setTimeout(function () {
      popup.style.display = "none"; // Hide the popup after 2 seconds
    }, 3400);
  },
  hide: (eID) => {
    helpers.id(eID).classList.add("hide");
  },
  unhide: (eID) => {
    helpers.id(eID).classList.remove("hide");
  },
  top_navActive: (eID) => {
    //helpers.id(eID).classList.add('active');
    //remove other btns
  },
  setActive: (ulID, elID) => {
    const listItems = document.querySelectorAll(`${ulID} li`);
    listItems.forEach(function (li) {
      const anchor = li.querySelector("a");
      anchor.classList.remove("active");
    });
    helpers.id(elID).classList.add("active");
  },

  transChartColors: (thingy) => {
    for (let i = 0; i < thingy.length; i++) {
      Object.keys(thingy[i]).forEach(function (key) {
        if (key === "color") {
          let value = thingy[i][key];
          if (typeof value === "string") {
            console.log("It's a string!");
          } else if (typeof value === "object") {
            if (value.hasOwnProperty("fill")) {
              thingy[i][key]["fill"] = am5.color(thingy[i][key]["fill"]);
            }
            if (value.hasOwnProperty("stroke")) {
              thingy[i][key]["stroke"] = am5.color(thingy[i][key]["stroke"]);
            }
          }
        }
      });
    }
  },

  fetchMethod: async (vars, method) => {
    helpers.toggleLoader(1);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(vars),
    };
    try {
      //https://spearbi.co.za/backend/nizza/api/v1/
      //http://localhost:3113/nizza/api/v1/
      const response = await fetch(`${process.env.URL}/${vars.side_nav}/${method}`, requestOptions);
      const status = response.status;
      const data = await response.json();
      helpers.toggleLoader(0);
      if (status === 201) {
        if (data.hasOwnProperty("message")) {
          helpers.successBox(data.message);
        }
        return data.data;
      } else {
        if (data.hasOwnProperty("message")) {
          helpers.errorBox(data.message);
        }
        return [];
      }
    } catch (error) {
      console.error("Fetch error:", error);
      helpers.toggleLoader(0);
      helpers.infoBox("technical error occured.");
      return [];
    }
  },
  postFiles: async (vars, files, method) => {
    helpers.toggleLoader(1);
    const formData = new FormData();
    formData.append("xlsxFile", files);
    Object.keys(vars).forEach(function (key) {
      formData.append(key, vars[key]);
    });
    try {
      //https://spearbi.co.za/backend/nizza/api/v1/
      //http://localhost:3113/nizza/api/v1/
      const response = await fetch(`${process.env.URL}/${method}`, {
        method: "POST",
        body: formData,
      });
      const status = response.status;
      const data = await response.json();
      helpers.toggleLoader(0);
      if (status === 201) {
        helpers.infoBox(data.message);
        return;
      } else {
        helpers.infoBox(data.message);
        return;
      }
    } catch (error) {
      helpers.toggleLoader(0);
      console.error("Error during file upload:", error);
      helpers.infoBox(`Exception occurred during file upload ${error.message}`);
    }
  },
  fetchZip: async (vars, method, name) => {
    helpers.toggleLoader(1);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(vars),
    };
    try {
      //https://spearbi.co.za/backend/nizza/api/v1/
      //http://localhost:3113/nizza/api/v1/
      const response = await fetch(`${process.env.URL}/${vars.side_nav}/${method}`, requestOptions);
      helpers.toggleLoader(0);
  
      if (response.ok) {
        // Download the ZIP file
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${name} ${vars.top_nav}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.log("Error response from server:", response);
        helpers.infoBox("Error response from server.");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      helpers.toggleLoader(0);
      helpers.infoBox("Technical error occurred.");
    }
  },
  fetchCsv: async (vars, method, name) => {
    helpers.toggleLoader(1);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(vars),
    };
    try {
      //https://mapma.tech/backend/reports/api/v1/
      //http://localhost:3113/nizza/api/v1/
      const response = await fetch(`${process.env.URL}/${vars.side_nav}/${method}`, requestOptions);
      helpers.toggleLoader(0);

      const contentType = response.headers.get("content-type");
      console.log("contentType: ", contentType);
      if (contentType && (contentType.includes("text/csv") || contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))) {
        // Download the CSV file
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${name}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.log("error", response);
        helpers.infoBox("error occured");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      helpers.toggleLoader(0);
      helpers.infoBox("technical error occured.");
    }
  },
  formArticle: (articlecode, supplier, top_nav) => {
    return `<div class="notification">
      <label id="articlecode">${articlecode}</label>
      <label id="supplier">${supplier}</label>
      <label id="top_nav">${top_nav}</label>
      <input type="text" placeholder="Description" id="description">
      <input type="text" placeholder="unit cost price" id="unitcostprice">
      <input type="text" placeholder="brand" id="brand">
    </div>`;
  },
};

window.hide = helpers.hide;
window.unhide = helpers.unhide;

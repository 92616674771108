const _ = require("lodash");
import { helpers } from "./help";

export class admin_uploads {
  constructor() {
    this.top_nav = pgstr.top_nav;
    this.pageTier = 10; //sets window.pageHist={5:'',10:'pnp',15:'',20:''}
    this.reference = "admin_sales";
    this.pageID = null;
    this.data = null;
    this.HclientID = null;
    this.dataselect = "IMPORT-dataselect";
    this.retailerselect = "IMPORT-retailerselect";
    this.file = "IMPORT-files";
  }
  initPage(data) {
    console.log(data);
    this.loadPage();
  }
  loadPage() {
    helpers.eListen(
      "IMPORT-submit",
      () => {
        this.verifyUpload();
      },
      "click"
    );
    // for (let i = 0; i < top_nav.length; i++) {
    //   let key = top_nav[i].key_text;
    //   let value = top_nav[i].value_text;
    //   helpers.addSelectOption(value, key, "IMPORT-retailerselect");
    // }
  }
  async verifyUpload() {
    let info = [helpers.getSelectedText(this.dataselect)]; //helpers.getSelectedText(this.retailerselect)
    let html = "";
    for (let i = 0; i < info.length; i++) {
      html += `<h3>${info[i]}</h3>`;
    }
    //verifyBox -> not in helpers cause cant execute the proceed method
    helpers.id("verifybox").innerHTML = html;
    helpers.eListen(
      "proceed",
      () => {
        hide("verifyBoxContainer");
        this.upload();
      },
      "click"
    );
    helpers.eListen(
      "cancel",
      () => {
        hide("verifyBoxContainer");
        helpers.id(this.file).value = "";
      },
      "click"
    );
    helpers.id("verifyBoxContainer").classList.remove("hide");
  }
  async upload() {
    const fileInput = helpers.id(this.file);
    const file = fileInput.files[0];
   // const retailer = helpers.getSelectedValue(this.retailerselect); //pnp,chk
    const datatype = helpers.getSelectedValue(this.dataselect); //sdc,sales
    if (file) {
      const vars = {}; //retailer: retailer, datatype: datatype
      await helpers.postFiles(vars, file, `data/uploads/${datatype}`); //customer / product / sage
      helpers.id(this.file).value = "";
    } else {
      helpers.infoBox("Please select a file");
      return;
    }
  }
}
export class admin_toggle {
  constructor() {
    this.top_nav = pgstr.top_nav;
    this.pageTier = 10; //sets window.pageHist={5:'',10:'pnp',15:'',20:''}
    this.reference = "admin_toggle";
    this.pageID = null;
    this.data = {};
    this.HclientID = pgstr.userid;
    this.dataselect = "TOGGLE-dataselect";
    this.retailerselect = "TOGGLE-retailerselect";
    this.tablecontainer = "tableResponseContainer";
  }
  initPage(data) {
    this.loadPage();
  }
  loadPage() {
    helpers.eListen(
      "TOGGLE-submit",
      () => {
        this.loadSkuTable();
      },
      "click"
    );
    this.loadDropDowns();
  }

async loadDropDowns(){
  for (let i = 0; i < top_nav.length; i++) {
    let key = top_nav[i].key_text;
    let value = top_nav[i].value_text;
    helpers.addSelectOption(value, key, 'TOGGLE-retailerselect');
  }
}

  async loadSkuTable() {
    const retailer = helpers.getSelectedValue(this.retailerselect); //pnp,chk
    const datatype = helpers.getSelectedValue(this.dataselect); //sdc,sales
    const res = await helpers.fetchMethod({ side_nav: "data", userid: this.HclientID, retailer: retailer,  datatype: datatype }, "/toggle/skuTable");
    this.data = res[0];
    this.buildTable();
  }

  buildTable() {
    const thead = helpers.id("thead");
    thead.innerHTML = "";
    let theadData = this.data.thead;
    for (let row of theadData) {
      const tr = helpers.divAtt("tr", "", "");
      for (let col of row) {
        console.log(col);
        let th = helpers.divAtt("th", "", "");
        th.innerHTML = col;
        tr.appendChild(th);
      }
      thead.appendChild(tr);
    }

    const tbody = helpers.id("tbody");
    tbody.innerHTML = "";
    let tbodyData = this.data.tbody;
    let dynamicevents = [];
    for (let row of tbodyData) {
      const tr = helpers.divAtt("tr", "", "");
      let td1_code = helpers.divAtt("td", "", "");
      td1_code.innerHTML = row.sage_sku;
      tr.appendChild(td1_code);

      let td2_desc = helpers.divAtt("td", "", "");
      let td2_input = helpers.divAtt("input", "type", "text");
      helpers.addAtt(td2_input, "id", `desc_${row.sage_sku}`);
      td2_input.classList.add("descriptionInput");
      td2_input.placeholder = row.product_description;
      td2_desc.appendChild(td2_input);
      tr.appendChild(td2_desc);

      // let td__cust_code = helpers.divAtt("td", "", "");
      // let td__cust_code_input = helpers.divAtt("input", "type", "text");
      // helpers.addAtt(td__cust_code_input, "id", `customer_code_${row.sage_sku}`);
      // td__cust_code_input.placeholder = row.customer_code;
      // td__cust_code.appendChild(td__cust_code_input);
      // tr.appendChild(td__cust_code);
      let td5_date = helpers.divAtt("td", "", "");
      let tempDate=row.date_created;
      td5_date.innerHTML = tempDate.substring(0,10);
      tr.appendChild(td5_date);

      let td6_radio = helpers.divAtt("td", "", "");
      let td6_div = helpers.divAtt("div", "class", "toggle-container");
      helpers.addAtt(td6_div, "id", `radiobutton_${row.sage_sku}`);
      let td6_toggle = helpers.divAtt("div", "class", "toggle-indicator");
      td6_div.appendChild(td6_toggle);
      td6_radio.appendChild(td6_div);
      if (row.active_yn) {
        td6_toggle.classList.add("on");
      }
      tr.appendChild(td6_radio);

      //desc
      dynamicevents.push({
        id: `desc_${row.sage_sku}`,
        func: (event) => {
          event = event || window.event; // Handle the case where event is undefined in older IE versions
          const key = event.key || event.keyCode;
          if (key === "Enter" || key === 13) {
            this.update_description(`desc_${row.sage_sku}`);
          }
        },
        evt: "keydown",
      });
      //toggle
      dynamicevents.push({
        id: `radiobutton_${row.sage_sku}`,
        func: () => {
          this.update_activeyn(`radiobutton_${row.sage_sku}`);
        },
        evt: "click",
      });
      // dynamicevents.push({
      //   id: `product_sub_group_${row.sage_sku}`,
      //   func: (event) => {
      //     event = event || window.event; // Handle the case where event is undefined in older IE versions
      //     const key = event.key || event.keyCode;
      //     if (key === "Enter" || key === 13) {
      //       this.update_product_sub_group(`product_sub_group_${row.sage_sku}`);
      //     }
      //   },
      //   evt: "keydown",
      // });

      // dynamicevents.push({
      //   id: `product_group_${row.sage_sku}`,
      //   func: (event) => {
      //     event = event || window.event; // Handle the case where event is undefined in older IE versions
      //     const key = event.key || event.keyCode;
      //     if (key === "Enter" || key === 13) {
      //       this.update_product_group(`product_group_${row.sage_sku}`);
      //     }
      //   },
      //   evt: "keydown",
      // });

      // dynamicevents.push({
      //   id: `customer_group_${row.sage_sku}`,
      //   func: (event) => {
      //     event = event || window.event; // Handle the case where event is undefined in older IE versions
      //     const key = event.key || event.keyCode;
      //     if (key === "Enter" || key === 13) {
      //       this.update_customer_group(`customer_group_${row.sage_sku}`);
      //     }
      //   },
      //   evt: "keydown",
      // });

      // dynamicevents.push({
      //   id: `customer_code_${row.sage_sku}`,
      //   func: (event) => {
      //     event = event || window.event; // Handle the case where event is undefined in older IE versions
      //     const key = event.key || event.keyCode;
      //     if (key === "Enter" || key === 13) {
      //       this.update_customer_code(`customer_code_${row.sage_sku}`);
      //     }
      //   },
      //   evt: "keydown",
      // });

      tbody.appendChild(tr);
    }

    for (let i = 0; i < dynamicevents.length; i++) {
      helpers.eListen(dynamicevents[i].id, dynamicevents[i].func, dynamicevents[i].evt);
    }
  }
  async update_description(eId) {
    const input = helpers.id(eId);
    const sage_sku = eId.replace("desc_", "");
    const retailer = helpers.getSelectedValue(this.retailerselect);
    const vars = { side_nav: "data", sage_sku: sage_sku, value: input.value, method: "update__description", retailer: retailer};
    const res = await helpers.fetchMethod(vars, "/toggle/updateSku");
  }
  async update_activeyn(eId) {
    let indicator = helpers.id(eId).children[0];
    let articlecode = eId.replace("radiobutton_", "");
    indicator.classList.toggle("on");
    let isOn = indicator.classList.contains("on");
    let retailer = helpers.getSelectedValue(this.retailerselect);

    const vars = { side_nav: "data", sage_sku: articlecode, value: isOn,method: "update__active_yn", retailer: retailer};
    const res = await helpers.fetchMethod(vars, "/toggle/updateSku");
    //call to toggle article
  }
  // async update_customer_code(eId) {
  //   const input = helpers.id(eId);
  //   const sage_sku = eId.replace("customer_code_", "");
  //   const retailer = helpers.getSelectedValue(this.retailerselect);
  //   const vars = { side_nav: "data", sage_sku: sage_sku, value: input.value, method: "update__customer_code", retailer: retailer };
  //   const res = await helpers.fetchMethod(vars, "/toggle/updateSku");
  // }
  // async update_customer_group(eId) {
  //   const input = helpers.id(eId);
  //   const sage_sku = eId.replace("customer_group_", "");
  //   const retailer = helpers.getSelectedValue(this.retailerselect);
  //   const vars = { side_nav: "data", sage_sku: sage_sku, value: input.value, method: "update__customer_group", retailer: retailer };
  //   const res = await helpers.fetchMethod(vars, "/toggle/updateSku");
  // }
  // async update_product_sub_group(eId) {
  //   const input = helpers.id(eId);
  //   const sage_sku = eId.replace("cost_", "");
  //   const retailer = helpers.getSelectedValue(this.retailerselect);
  //   const vars = { side_nav: "data", sage_sku: sage_sku, value: input.value, method: "update__product_sub_group", retailer: retailer};
  //   const res = await helpers.fetchMethod(vars, "/toggle/updateSku");
  // }
  // async update_product_group(eId) {
  //   const input = helpers.id(eId);
  //   const sage_sku = eId.replace("product_group_", "");
  //   const retailer = helpers.getSelectedValue(this.retailerselect);
  //   const vars = { side_nav: "data", sage_sku: sage_sku, value: input.value, method: "update__product_group", retailer: retailer };
  //   const res = await helpers.fetchMethod(vars, "/toggle/updateSku");
  // }
}
export class admin_notifications {
  constructor() {
    this.top_nav = pgstr.top_nav;
    this.pageTier = 10; //sets window.pageHist={5:'',10:'pnp',15:'',20:''}
    this.reference = "admin_notifications";
    this.pageID = null;
    this.data = null;
    this.HclientID = pgstr.userid;
    this.notifications = {};
  }
  initPage(data) {
    //if (this.HclientID == 1 || this.HclientID == 2) {
    //this.loadPage();
    //}
  }
  async loadPage() {
    const res = await helpers.fetchMethod({ page: "data", userid: this.HclientID }, "notifications");
    this.notifications = res;
    // if (this.notifications.length) {
    //   helpers.eListen(
    //     "notificationsvg",
    //     () => {
    //       this.openNotifications();
    //     },
    //     "click"
    //   );
    // }

    this.setNrNotifications();
  }

  setNrNotifications() {
    helpers.id("nrNotifications").innerHTML = this.notifications.length;
    //add html to notificationbox
  }

  openNotifications() {
    let html = "";
    let dynamicElems = [];

    for (let i = 0; i < this.notifications.length; i++) {
      html += `<div class="notification" id="notification_${i}">
      <p>Supplier: ${this.notifications[i].suppliercode}</p>
      <p>Retailer: ${this.notifications[i].retailercode}</p>
      <p>Article: ${this.notifications[i].articlecode}</p>
      <p>Table: ${this.notifications[i].tablename}</p>
      </div>`; //${info[i]}
      dynamicElems.push({
        id: `notification_${i}`,
        evt: () => {
          this.openNotification(i);
        },
      });
    }
    helpers.id("verifybox").innerHTML = html;

    for (let i = 0; i < dynamicElems.length; i++) {
      helpers.eListen(dynamicElems[i].id, dynamicElems[i].evt, "click");
    }
    helpers.eListen(
      "proceed",
      () => {
        hide("verifyBoxContainer");
      },
      "click"
    );
    helpers.eListen(
      "cancel",
      () => {
        hide("verifyBoxContainer");
      },
      "click"
    );
    helpers.id("verifyBoxContainer").classList.remove("hide");
  }
  openNotification(index) {
    /*  { itemid: 4, suppliercode: 'NIZZA', retailercode: 'pnp', articlecode: '392007',
     tablename: 'pnp_sdc', createddate: 2023-12-07T22:00:00.000Z, activeyn: true } */
    const item = this.notifications[index];
    const type = item.type;
    const code = item.articlecode;
    const supplier = item.suppliercode;
    const retailer = item.retailercode;
    const htmlForm = helpers[`form${type}`](code, supplier, retailer); //return html so u can have eListen on form submitn
    helpers.id("verifybox").innerHTML = htmlForm;
    helpers.eListen(
      "proceed",
      () => {
        hide("verifyBoxContainer");
        this.submitArticle(item);
      },
      "click"
    );
    helpers.eListen(
      "cancel",
      () => {
        hide("verifyBoxContainer");
      },
      "click"
    );
    helpers.id("verifyBoxContainer").classList.remove("hide");
  }

  async submitArticle(item) {
    const description = helpers.id("description").value;
    const unitcostprice = helpers.id("unitcostprice").value;
    const brand = helpers.id("brand").value;

    if (description.length == 0) {
      helpers.infoBox("please fill in description");
      helpers.id("verifyBoxContainer").classList.remove("hide");
      return;
    }
    if (unitcostprice.length == 0) {
      helpers.infoBox("please fill in unit cost price");
      helpers.id("verifyBoxContainer").classList.remove("hide");
      return;
    }
    if (brand.length == 0) {
      helpers.infoBox("please fill in brand");
      helpers.id("verifyBoxContainer").classList.remove("hide");
      return;
    }
    item.page = "data";
    item.userid = this.HclientID;
    item.description = description;
    item.unitcostprice = unitcostprice;
    item.brand = brand;

    const res = await helpers.fetchMethod(item, "insertArticle");
  }
}

// import { forEach } from "lodash";
const _ = require("lodash");
import { helpers } from "./help";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";

export class Login {
  constructor() {
    //this.top_nav = pgstr.top_nav;
    this.pageTier = 10; //sets window.pageHist={5:'',10:'pnp',15:'',20:''}
    this.reference = "login";
    this.pageID = null;
    this.data = null;
    this.HclientID = null;
  }
  initPage(data) {
    this.loadPage();
  }
  loadPage() {
    helpers.eListen(
      "submit",
      () => {
        this.authenticate();
      },
      "click"
    );
  }
  async authenticate() {
    const vars = {};
    vars.username = helpers.id("username").value.trim();
    vars.password = helpers.id("password").value.trim();
    vars.side_nav = "login";
    const res = await helpers.fetchMethod(vars, "authenticate");
    if (res.length) {
      this.buildHomePage(res[0].user,res[0].top_nav,res[0].side_nav);
    }
  }
  buildHomePage(user,top_nav,side_nav){
    window.pgstr = {side_nav:'sales',top_nav:'pnp'};
    window.top_nav=top_nav;
    for (let i = 0; i < top_nav.length; i++) {
      let key = top_nav[i].key_text;
      let value = top_nav[i].value_text;

      helpers.id(key).innerHTML=value;
      helpers.id(key).parentElement.style.display='';
      helpers.eListen( key, () => { let obj = {}; obj['top_nav'] = key; helpers.prep(obj); }, "click" );
    }
    for (let i = 0; i < side_nav.length; i++) {
      let key_text = side_nav[i].key_text;
      let value_text = side_nav[i].value_text;
      let html_page = side_nav[i].html_page;
      helpers.id(key_text).parentElement.style.display='';
      helpers.id(key_text).innerHTML=value_text;
      helpers.eListen( key_text, () => { let obj = {}; obj['side_nav'] = key_text; helpers.prep(obj); }, "click" );
    }
    helpers.prep({}); 
  }
  // buildDataPage(top_nav){
  //   for (let i = 0; i < top_nav.length; i++) {
  //     let key = top_nav[i].key_text;
  //     let value = top_nav[i].value_text;
  //     helpers.addSelectOption(value,key,'IMPORT-retailerselect');
  //   }
  // }
}

export class Sales {
  constructor() {
    this.top_nav = pgstr.top_nav;
    this.pageTier = 10; //sets window.pageHist={5:'',10:'pnp',15:'',20:''}
    this.reference = "sales";
    this.pageID = null;
    this.data = null;
    this.HclientID = null;
  }
  async initPage(data) {

  //  this.top10_1={};
  //  this.top10_2={};
  //  this.top10_3={};
  //  this.top10_4={};

    this.loadPage();
  }
  loadPage() {
    helpers.eListen("downloadsvg",()=>{this.openCSVpopup();},"click");
    //helpers.eListen("csv1",()=>{this.downloadCsv1();},"click");
    helpers.eListen("csv1",()=>{this.openCSV1DatePicker();},"click");
    helpers.eListen("csv2",()=>{this.downloadCsv2();},"click");
    helpers.eListen("closePopup",()=>{this.closeCSV1DatePicker();},"click");
    
    // helpers.eListen("csv2",()=>{this.downloadCsv2();},"click"); //phase 2
    // helpers.eListen("csv3",()=>{this.downloadCsv3();},"click"); //phase 2
    this.setCSV1DatePickers();
    this.build_table1();
    
    this.buildCharts();
  }
  async openCSVpopup(){
    helpers.unhide('csv-box');
    setTimeout(function() {
      helpers.hide('csv-box');
    }, 6000);
  }
  async setCSV1DatePickers(){
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
    helpers.id("CSV1-year").value = currentYear;
    helpers.id("CSV1-month").value = currentMonth;
  }
  async closeCSV1DatePicker(){
    helpers.id('popup').style.display='none';
  }
  async openCSV1DatePicker(){
    helpers.eListen("CSV1-submit",()=>{this.downloadCsv1();},"click");
    helpers.id('popup').style.display='block';
  }
  async openCSV2DatePicker(){
    helpers.eListen("CSV1-submit",()=>{this.downloadCsv2();},"click");
    helpers.id('popup').style.display='block';
  }


  async downloadCsv1(){
    helpers.hide('csv-box');
    let vars=pgstr;
    vars.dates={};
    vars.dates.year=helpers.getSelectedValue('CSV1-year');
    vars.dates.month=helpers.getSelectedValue('CSV1-month');
    this.closeCSV1DatePicker();
    await helpers.fetchCsv(vars, "csv_sales","Sales Database");
  }
  async downloadCsv2(){
    helpers.hide('csv-box');
    let vars=pgstr;
    vars.dates={};
    vars.dates.year=helpers.getSelectedValue('CSV1-year');
    vars.dates.month=helpers.getSelectedValue('CSV1-month');
    this.closeCSV1DatePicker();
    await helpers.fetchCsv(vars, "csv_missing","Excluded Sales");
  }


  async build_table1() {
    const res = await helpers.fetchMethod(pgstr, "html_sales");
    if (_.isEmpty(res)) {
      console.warn("build_table1 terminated. Empty response", res);
      return;
    }

    this.thead = res.tableData.thead;
    this.tbody = res.tableData.tbody;
    console.log(this.thead);
    //build head
    const thead = helpers.id("thead");
    thead.innerHTML = "";
    let theadData = this.thead;
    for (let row of theadData) {
      const tr = helpers.divAtt("tr", "", "");
      for (let col of row) {
        let th = helpers.divAtt("th", "", "");
        if (Array.isArray(col)) {
          th.innerHTML = col[0];
          if (Array.isArray(col[1])) {
            for (let i = 0; i < col[1].length; i++) {
              let attr = col[1][i];
              helpers.addAtt(th, attr.key, attr.value);
            }
          } else {
            let attr = col[1];
            helpers.addAtt(th, attr.key, attr.value);
          }
        } else {
          th.innerHTML = col;
        }
        tr.appendChild(th);
      }
      thead.appendChild(tr);
    }

    //build body
    const tbody = helpers.id("tbody");
    tbody.innerHTML = "";
    let tbodyData = this.tbody;
    for (let row of tbodyData) {
      const tr = helpers.divAtt("tr", "", "");
      for (let col of row) {
        let td=helpers.divAtt("td", "", "");
        if (Array.isArray(col)) {
          if (Array.isArray(col[1])) {
            for (let i = 0; i < col[1].length; i++) {
              let attr = col[1][i];
              helpers.addAtt(td, attr.key, attr.value);
              td.innerHTML = col[0];
            }
          } else {
            let attr = col[1];
            helpers.addAtt(td, attr.key, attr.value);
            td.innerHTML = col[0];
          }
        } else {
         // td = helpers.divAtt("td", "", "");
          td.innerHTML = col;
        }
        tr.appendChild(td);
      }
      tbody.appendChild(tr);
    }
  }
  async buildCharts(){
    const res = await helpers.fetchMethod(pgstr, "chart6_sales");
    if (_.isEmpty(res)) {
      console.warn("chart6 terminated. Empty response.", res);
      return;
    }
    this.chart6A = res.graph.chart6A;
    this.chart6B=res.graph.chart6B;
    this.brand6A = res.brands.chart6A;
    this.brand6B=res.brands.chart6B;
    this.build_chart6A();
    if(this.chart6B.length){
      this.build_chart6B();
    }
  }

  async build_chart6A() {   

    helpers.transChartColors(this.chart6A);
    helpers.id('graph6A-brand').innerHTML=this.brand6A;
    var root = am5.Root.new("graph6A");
    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        paddingRight: 40,
        //paddingTop: 20, // Add padding above the x-axis
       // paddingBottom: -20, // Add padding underneath the graph
       // marginBottom: 40, // Add padding underneath the graph
        // height: 240,
      })
    );
    chart.zoomOutButton.set("forceHidden", true);
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#,###.' %'",
        tooltipNumberFormat: "#,###.' %'",
        extraTooltipPrecision: 1,
        stroke: am5.color(0x000000),
        //min: -500,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 30,
        }),
      })
    );
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "month", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
          cellStartLocation: 0.2,
          cellEndLocation: 0.8,
        }),
      })
    );
    xAxis.get("dateFormats")["month"] = "MMM-yy";
    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0x000000),
      fontSize: 13,
    });
    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0x000000),
      fontSize: 13,
    });
    yAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    xAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "% vs LY)",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {}),
        clustered: true,
      })
    );
    series.columns.template.setAll({
      width: am5.percent(100),
    });
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        autoRotate: true,
        autoRotateAngle: 90,
        sprite: am5.Label.new(root, {
          text: "[bold]{valueY} %",
          fontSize: 12,
          fill: am5.color(0x000000),
          dy: -3,
          // centerY: am5.p100,
          centerX: am5.p50,
          populateText: true,
        }),
      });
    });
    series.columns.template.setAll({
      templateField: "color",
    });
    series.columns.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            brighten: -0.8,
          },
          {
            brighten: -0.6,
          },
          {
            brighten: -0.5,
          },
          {
            brighten: -0.6,
          },
          {
            brighten: -0.8,
          },
        ],
        rotation: 0,
      })
    );
    series.get("tooltip").label.set("text", "[bold]{name}[/]\n{valueY}");
    series.data.setAll(this.chart6A);
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
        xAxis: xAxis,
      })
    );
    xAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );
    yAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );
  }

  async build_chart6B() {   

    helpers.transChartColors(this.chart6B);
    helpers.id('graph6B-brand').innerHTML=this.brand6B;
    helpers.id('graph6B-title').style.display='';
    var root = am5.Root.new("graph6B");
    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        paddingRight: 40,
        //paddingTop: 20, // Add padding above the x-axis
       // paddingBottom: -20, // Add padding underneath the graph
       // marginBottom: 40, // Add padding underneath the graph
        // height: 240,
      })
    );
    chart.zoomOutButton.set("forceHidden", true);
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#,###.' %'",
        tooltipNumberFormat: "#,###.' %'",
        extraTooltipPrecision: 1,
        stroke: am5.color(0x000000),
        //min: -500,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 30,
        }),
      })
    );
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "month", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
          cellStartLocation: 0.2,
          cellEndLocation: 0.8,
        }),
      })
    );
    xAxis.get("dateFormats")["month"] = "MMM-yy";
    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0x000000),
      fontSize: 13,
    });
    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0x000000),
      fontSize: 13,
    });
    yAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    xAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "% vs LY)",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {}),
        clustered: true,
      })
    );
    series.columns.template.setAll({
      width: am5.percent(100),
    });
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        autoRotate: true,
        autoRotateAngle: 90,
        sprite: am5.Label.new(root, {
          text: "[bold]{valueY} %",
          fontSize: 12,
          fill: am5.color(0x000000),
          dy: -3,
          // centerY: am5.p100,
          centerX: am5.p50,
          populateText: true,
        }),
      });
    });
    series.columns.template.setAll({
      templateField: "color",
    });
    series.columns.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            brighten: -0.8,
          },
          {
            brighten: -0.6,
          },
          {
            brighten: -0.5,
          },
          {
            brighten: -0.6,
          },
          {
            brighten: -0.8,
          },
        ],
        rotation: 0,
      })
    );
    series.get("tooltip").label.set("text", "[bold]{name}[/]\n{valueY}");
    series.data.setAll(this.chart6B);
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
        xAxis: xAxis,
      })
    );
    xAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );
    yAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );
  }
}

export class Summary_Sales {
  constructor() {
    this.top_nav = pgstr.top_nav;
    this.pageTier = 10; //sets window.pageHist={5:'',10:'pnp',15:'',20:''}
    this.reference = "summary_sales";
    this.pageID = null;
    this.data = null;
    this.HclientID = null;
  }
  async initPage(data) {

  //  this.top10_1={};
  //  this.top10_2={};
  //  this.top10_3={};
  //  this.top10_4={};

    this.loadPage();
  }
  loadPage() {
    // helpers.eListen("downloadsvg",()=>{this.openCSVpopup();},"click");
    // //helpers.eListen("csv1",()=>{this.downloadCsv1();},"click");
    // helpers.eListen("csv1",()=>{this.openCSV1DatePicker();},"click");
    // helpers.eListen("closePopup",()=>{this.closeCSV1DatePicker();},"click");
    // helpers.eListen("CSV1-submit",()=>{this.downloadCsv1();},"click");
    // // helpers.eListen("csv2",()=>{this.downloadCsv2();},"click"); //phase 2
    // // helpers.eListen("csv3",()=>{this.downloadCsv3();},"click"); //phase 2
    this.build_table1();
    this.build_chart6();
  }

  async build_table1() {
    const res = await helpers.fetchMethod({ side_nav: "summary" }, "table-overview");
    if (_.isEmpty(res)) {
      console.warn("build_table1 terminated. Empty response", res);
      return;
    }
    console.log(res);

    await helpers.buildTableData("overview-thead","overview-tbody",res.tableData.thead,res.tableData.tbody);

  }

  async build_chart6() {   
    const res = await helpers.fetchMethod({ side_nav: "summary" }, "chart6-summary");
    if (_.isEmpty(res)) {
      console.warn("chart6 terminated. Empty response.", res);
      return;
    }
    this.chart6 = res;
    console.log(res);
    helpers.transChartColors(this.chart6);

    var root = am5.Root.new("graph6");
    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        paddingRight: 40,
        //paddingTop: 20, // Add padding above the x-axis
       // paddingBottom: -20, // Add padding underneath the graph
       // marginBottom: 40, // Add padding underneath the graph
        // height: 240,
      })
    );
    chart.zoomOutButton.set("forceHidden", true);
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#,###.' %'",
        tooltipNumberFormat: "#,###.' %'",
        extraTooltipPrecision: 1,
        stroke: am5.color(0x000000),
        //min: -500,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 30,
        }),
      })
    );
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "month", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
          cellStartLocation: 0.2,
          cellEndLocation: 0.8,
        }),
      })
    );
    xAxis.get("dateFormats")["month"] = "MMM-yy";
    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0x000000),
      fontSize: 13,
    });
    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0x000000),
      fontSize: 13,
    });
    yAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    xAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "% vs LY)",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {}),
        clustered: true,
      })
    );
    series.columns.template.setAll({
      width: am5.percent(100),
    });
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        autoRotate: true,
        autoRotateAngle: 90,
        sprite: am5.Label.new(root, {
          text: "[bold]{valueY} %",
          fontSize: 12,
          fill: am5.color(0x000000),
          dy: -3,
          // centerY: am5.p100,
          centerX: am5.p50,
          populateText: true,
        }),
      });
    });
    series.columns.template.setAll({
      templateField: "color",
    });
    series.columns.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            brighten: -0.8,
          },
          {
            brighten: -0.6,
          },
          {
            brighten: -0.5,
          },
          {
            brighten: -0.6,
          },
          {
            brighten: -0.8,
          },
        ],
        rotation: 0,
      })
    );
    series.get("tooltip").label.set("text", "[bold]{name}[/]\n{valueY}");
    series.data.setAll(this.chart6);
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
        xAxis: xAxis,
      })
    );
    xAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );
    yAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );
  }
}

import {Login,Sales,Summary_Sales} from'./client';
import {admin_notifications, admin_uploads,admin_toggle} from './admin';
import {helpers} from'./help';

export const dist={
    login:(recipe)=>{
        let res={};
        helpers.initPageInstance(new Login(),res);
    },
    sales:(recipe)=>{
        let res={};
        helpers.initPageInstance(new Sales(),res);
    },
    strikeRate:(recipe)=>{
        //fetch data
        let res={tableData:[]};
        helpers.initPageInstance(new StrikeRate(),res);
    },
    monthEnd:(recipe)=>{
        //fetch data
        let res={tableData:[]};
        helpers.initPageInstance(new MonthEnd(),res);
    },
    costing:(recipe)=>{
        //fetch data
        let res={tableData:[]};
        helpers.initPageInstance(new Costing(),res);
    },
    admin_sales:(recipe)=>{
        //fetch data
        let res={tableData:[]};
        helpers.initPageInstance(new admin_uploads(),res);
        helpers.initPageInstance(new admin_toggle(),res);
    },
    summary_sales:(recipe)=>{
        //fetch data
        let res={tableData:[]};
        helpers.initPageInstance(new Summary_Sales(),res);
    },
    admin_notifications:(recipe)=>{
        //fetch data
        let res={};
        helpers.initPageInstance(new admin_notifications(),res);
    },
};